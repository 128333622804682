import React from "react";

import "./Main.css";
import Alena from "../../Photo/Alena2.png";

import Button from "../Button/Button";

function Main() {
  return (
    <div className="main" id="Main">
      {/* <div className="main-text-container">
        
      </div> */}
      <div className="container">
        <div className="text">
        <h2 className="main-text color-text-main">Специалист <br/>по красоте</h2>
          <p className="p-text-h color-text-main">
            Добро пожаловать на персональный сайт мастера!
          </p>
          <p className="p-text color-text-main">
            Я верю в важность индивидуального подхода к каждому клиенту. Перед
            началом любой процедуры я провожу детальную консультацию, чтобы
            понять ваши пожелания и ожидания. Это позволяет мне создавать именно
            тот результат, который будет вас радовать.
          </p>
          <div className="button-div">
            <a
              href="https://n1274061.yclients.com/company/687678/personal/menu?o=m1980662"
              target="_blank"
              rel="noreferrer"
            >
              <Button>Записаться</Button>
            </a>
          </div>
        </div>
        <div className="image-main">
          <img className="alena" src={Alena} alt="Alena" />
        </div>
      </div>
    </div>
  );
}

export default Main;
