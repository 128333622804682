import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <div className="footer" id="Footer">
      <div className="footer-content">
        <div className="footer-adrress">
          <address className="footer-adrress-block">
            <svg
              width="27"
              height="20"
              viewBox="0 0 27 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M16.3916 0.685547C15.3404 0.228516 14.2129 0 13.009 0C11.8052 0 10.6777 0.228516 9.62642 0.685547C8.57519 1.14258 7.65112 1.76888 6.85422 2.56445C6.07427 3.3431 5.4554 4.25716 4.9976 5.30664C4.55676 6.35612 4.33634 7.48177 4.33634 8.68359C4.33634 9.5638 4.46351 10.4102 4.71784 11.2227C4.97217 12.0352 5.32823 12.7799 5.78603 13.457L13.009 26L20.3846 13.2539C20.7915 12.5938 21.1052 11.8743 21.3256 11.0957C21.563 10.3171 21.6817 9.51302 21.6817 8.68359C21.6817 7.48177 21.4613 6.35612 21.0204 5.30664C20.5627 4.25716 19.9353 3.3431 19.1384 2.56445C18.3584 1.76888 17.4429 1.14258 16.3916 0.685547ZM13.009 13.0085C15.4063 13.0085 17.3496 11.0684 17.3496 8.67513C17.3496 6.2819 15.4063 4.3418 13.009 4.3418C10.6118 4.3418 8.66844 6.2819 8.66844 8.67513C8.66844 11.0684 10.6118 13.0085 13.009 13.0085Z"
                fill="#403F58"
              />
            </svg>
            <div className="name-adrress">
              Адрес
              <div className="small-text adrress-footer">
                г. Чехов ул. Весенняя ул., 31
              </div>
            </div>
          </address>
          <address className="footer-adrress-block">
            <svg
              width="27"
              height="20"
              viewBox="0 0 27 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.6254 26C17.9649 26 17.1689 25.763 16.2374 25.2891C15.3228 24.7982 14.3321 24.1634 13.2651 23.3848C12.1981 22.5892 11.0973 21.6921 9.96254 20.6934C8.84475 19.6947 7.7439 18.679 6.65999 17.6465C5.59301 16.6309 4.65305 15.5391 3.84011 14.3711C3.02717 13.2201 2.34126 12.0859 1.78236 10.9688C1.24041 9.85156 0.817002 8.79362 0.51215 7.79492C0.224235 6.81315 0.0548732 5.98372 0.00406468 5.30664C-0.0298077 4.69727 0.148022 4.09635 0.537555 3.50391C0.910151 2.89453 1.39283 2.35286 1.9856 1.87891C2.5953 1.38802 3.25581 0.973307 3.96713 0.634766C4.67845 0.296224 5.3559 0.0846354 5.99947 0L9.58148 7.26172L7.77777 9.34375C7.96407 9.64844 8.47216 10.3086 9.30203 11.3242C10.1319 12.3398 10.9618 13.2708 11.7916 14.1172C12.6215 14.9466 13.4853 15.7422 14.3829 16.5039C15.2974 17.2487 15.9071 17.7057 16.212 17.875L18.7778 15.9707C18.7778 15.9707 19.0488 16.0892 19.5908 16.3262C20.1327 16.5632 20.7678 16.8424 21.4961 17.1641C22.2243 17.4688 22.9526 17.7904 23.6809 18.1289C24.4091 18.4674 24.9595 18.7298 25.3321 18.916C25.3321 18.916 25.3999 18.9753 25.5354 19.0938C25.6709 19.2122 25.7894 19.4238 25.891 19.7285C25.9757 20.0332 26.018 20.2533 26.018 20.3887C26.018 20.5072 26.018 20.5664 26.018 20.5664C25.7132 21.8698 25.0781 22.8854 24.1127 23.6133C23.1643 24.3411 22.1227 24.959 20.988 25.4668C20.988 25.4668 20.8779 25.5091 20.6577 25.5938C20.4376 25.6784 20.1497 25.763 19.794 25.8477C19.4214 25.9492 19.1335 26 18.9303 26C18.727 26 18.6254 26 18.6254 26Z"
                fill="#403F58"
              />
            </svg>
            <div className="name-adrress">
              Телефон
              <div>
                <a
                  href="tel:+79990998935"
                  className="small-text adrress-footer tel"
                >
                  +7 (999) 099-89-35
                </a>
              </div>
            </div>
          </address>
          
        </div>
        <div className="svg-container">

          <a className="svg-container-div" href="https://wa.me/+79777990281" target="_blank">+7 (999) 099-89-35
            <svg
              className="svg-social wt"
              width="20"
              height="20"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="40" height="40" rx="20" fill="#48C95F" />
              <path
                d="M27.9268 12.0625C25.9512 10.0937 23.3171 9 20.5366 9C14.7561 9 10.0732 13.6667 10.0732 19.4271C10.0732 21.25 10.5854 23.0729 11.4634 24.6042L10 30L15.561 28.5417C17.0976 29.3438 18.7805 29.7812 20.5366 29.7812C26.3171 29.7812 31 25.1146 31 19.3542C30.9268 16.6563 29.9024 14.0312 27.9268 12.0625ZM25.5854 23.1458C25.3659 23.7292 24.3415 24.3125 23.8293 24.3854C23.3902 24.4583 22.8049 24.4583 22.2195 24.3125C21.8537 24.1667 21.3415 24.0208 20.7561 23.7292C18.122 22.6354 16.439 20.0104 16.2927 19.7917C16.1463 19.6458 15.1951 18.4062 15.1951 17.0937C15.1951 15.7812 15.8537 15.1979 16.0732 14.9062C16.2927 14.6146 16.5854 14.6146 16.8049 14.6146C16.9512 14.6146 17.1707 14.6146 17.3171 14.6146C17.4634 14.6146 17.6829 14.5417 17.9024 15.0521C18.122 15.5625 18.6341 16.875 18.7073 16.9479C18.7805 17.0938 18.7805 17.2396 18.7073 17.3854C18.6341 17.5312 18.561 17.6771 18.4146 17.8229C18.2683 17.9687 18.1219 18.1875 18.0488 18.2604C17.9024 18.4062 17.7561 18.5521 17.9024 18.7708C18.0488 19.0625 18.561 19.8646 19.3659 20.5937C20.3902 21.4687 21.1951 21.7604 21.4878 21.9063C21.7805 22.0521 21.9268 21.9792 22.0732 21.8333C22.2195 21.6875 22.7317 21.1042 22.878 20.8125C23.0244 20.5208 23.2439 20.5938 23.4634 20.6667C23.6829 20.7396 25 21.3958 25.2195 21.5417C25.5122 21.6875 25.6585 21.7604 25.7317 21.8333C25.8049 22.0521 25.8049 22.5625 25.5854 23.1458Z"
                fill="white"
              />
            </svg>
          </a>
          <a className="svg-container-div" href="https://t.me/alena_brow1" target="_blank">t.me/alena_brow1
            <svg
              className="svg-social tg"
              width="19"
              height="19"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="40" height="40" rx="20" fill="#27A6E5" />
              <path
                d="M28 12.6022L24.9946 28.2923C24.9946 28.2923 24.5741 29.3801 23.4189 28.8584L16.4846 23.3526L16.4524 23.3364C17.3891 22.4654 24.6524 15.7027 24.9698 15.3961C25.4613 14.9214 25.1562 14.6387 24.5856 14.9974L13.8568 22.053L9.71764 20.6108C9.71764 20.6108 9.06626 20.3708 9.00359 19.8491C8.9401 19.3265 9.73908 19.0439 9.73908 19.0439L26.6131 12.1889C26.6131 12.1889 28 11.5579 28 12.6022Z"
                fill="#FEFEFE"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
