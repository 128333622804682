import React from 'react';
import Navigation from './Navigation/Navigation';
import './Header.css'


function Header() {
  return (
    <div className="Header">
      <header className="header-header">
        <h1 className="logo">Алена Трошина</h1>
        <Navigation />
      </header>
    </div>
  );
}

export default Header;