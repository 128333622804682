import React from "react";
import "./AboutMe.css";

import AlenaAbout from "../../Photo/AlenaAbout.png";

import orig1 from "../../Photo/orig1.png";
import orig2 from "../../Photo/orig2.png";
import orig3 from "../../Photo/orig3.png";

function AboutMe() {
  return (
    <div className="content" id="AboutMe">
      <div className="heading">
        <h2 className="text-ab-hh">Обо мне</h2>
      </div>
      <div className="container">
        <div className="text-a">
        <h3 className="text-ab-h">Мастер-бровист и специалист по красоте</h3>
        <p className="text-ab-p">
          Меня зовут Алёна Трошина , и я с гордостью представляю себя как
          мастера-бровиста с восьмилетним стажем, специалиста по ламинированию
          ресниц с семилетним опытом и эксперта по перманентному макияжу с
          двухлетним стажем. Моя работа — это не просто профессия, а настоящее
          призвание, которое позволяет мне помогать людям чувствовать себя
          красивыми и уверенными в себе.
        </p>
        <h4 className="text-ab-h">Путь в индустрию красоты</h4>
        <p className="text-ab-p">
          Мой путь в мир красоты начался с увлечения эстетикой и желанием
          создать гармоничный образ. Я прошла множество школ,курсов и тренингов,
          чтобы освоить различные техники и методы работы, и с каждым годом моя
          страсть к этому делу только усиливалась. Я постоянно обучаюсь и
          совершенствую свои навыки, чтобы предложить своим клиентам только
          самое лучшее.
        </p>
        </div>
        <div className="image image-h">
          <img className="alena" src={AlenaAbout} alt="AlenaAbout" />
        </div>
      </div>
      <div className="gallery container">
        <div className="gallery-img orig1">
          {/* <img className="gallery-img orig1" src={orig1} alt="orig1" /> */}
          </div>
          <div className="gallery-img orig2">
          {/* <img className="gallery-img orig1" src={orig1} alt="orig1" /> */}
          </div>
          <div className="gallery-img orig3">
          {/* <img className="gallery-img orig1" src={orig1} alt="orig1" /> */}
          </div>
          {/* <img className="gallery-img orig2" src={orig2} alt="orig2" />
          <img className="gallery-img orig3" src={orig3} alt="orig3" /> */}
        </div>
    </div>
  );
}

export default AboutMe;
