import React, { useEffect, useState } from "react";
import "./Questions.css";

import Faq from "react-faq-component";

function Questions() {
  const data = {
    // title: "FAQ (How it works)",
    rows: [
      {
        title: "Я не знаю какой хочу цвет, боюсь темного.",
        content: `У нас огромная палитра оттенков! Поэтому мы подбираем идеальный для вашего образа оттенок.`,
      },
      {
        title: "Это очень больно?",
        content:
          "Это совсем не больно! Так как используется американская первичная анестезия. Большинство клиентов спят на процедуре.",
      },
      {
        title: "Как проходит заживление? Я смогу выйти на улицу?",
        content: `Никаких корок, легкое шелушение от 2 до 10 дней, 
        в зависимости от зоны перманентного макияжа. Сразу после процедуры можно в ресторан! `,
      },
    ],
    
  };

  const styles = {
    // bgColor: '#D9D9D9',
    // titleTextColor: 'black',
    // titleTextSize: '48px',
    rowTitleColor: 'black',
    // rowTitleTextSize: 'medium',
    // rowContentColor: 'grey',
    rowContentTextSize: '16px',
    // rowContentPaddingTop: '10px',
    rowContentPaddingBottom: '10px',
    rowContentPaddingLeft: '50px',
    // rowContentPaddingRight: '150px',
    // arrowColor: "red",
    //transitionDuration: "1s",
    // timingFunc: "ease"
  };

  const config = {
    animate: true,
    arrowIcon: "V",
    openOnload: 0,
    expandIcon: "+",
    collapseIcon: "-",
};

  return (
    <div className="content" id="Questions">
      <div className="heading">
        <h2 className="text-ab-hh">Вопросы</h2>
      </div>
      <div className="container-faq">
      <div className="faq">
            <Faq
                data={data}
                styles={styles}
                config={config}
            />
        </div>
      </div>
    </div>
  );
}

export default Questions;
