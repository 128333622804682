import "./ServicesContent.css";

import React from "react";

import brow1 from "../../Photo/Brow/brow (1).jpg";
import brow2 from "../../Photo/Brow/brow (2).jpg";
import brow3 from "../../Photo/Brow/brow (3).jpg";
// import brow4 from "../../Photo/Brow/brow (4).jpg";
import brow5 from "../../Photo/Brow/brow (5).jpg";
import brow6 from "../../Photo/Brow/brow (6).jpg";
// import brow8 from "../../Photo/Brow/brow8.jpg";
// import brow9 from "../../Photo/Brow/brow9.jpg";

import lips1 from "../../Photo/Lips/lips (1).jpg";
import lips2 from "../../Photo/Lips/lips (2).jpg";
import lips3 from "../../Photo/Lips/lips (3).jpg";
import lips4 from "../../Photo/Lips/lips (4).jpg";
import lips5 from "../../Photo/Lips/lips (5).jpg";
// import lips6 from "../../Photo/Lips/lips6.jpg";

// import cilia1 from "../../Photo/Cilia/cilia (1).jpg";
// import cilia2 from "../../Photo/Cilia/cilia (2).jpg";
// // import cilia3 from "../../Photo/Cilia/cilia (3).jpg";
// import cilia4 from "../../Photo/Cilia/cilia (4).jpg";
import cilia5 from "../../Photo/Cilia/cilia (5).jpg";
import cilia6 from "../../Photo/Cilia/cilia (6).jpg";
import cilia7 from "../../Photo/Cilia/cilia (7).jpg";
import cilia8 from "../../Photo/Cilia/cilia (8).jpg";
import cilia9 from "../../Photo/Cilia/cilia (9).jpg";
import cilia10 from "../../Photo/Cilia/cilia (10).jpg";
import cilia11 from "../../Photo/Cilia/cilia (11).jpg";

import ImageGallery from "react-image-gallery";
import "./image-gallery.css";

function ServicesContent() {
  const brow = [
    {
      original: brow1,
    },
    {
      original: brow2,
    },
    {
      original: brow3,
    },
    // {
    //   original: brow4,
    // },
    {
      original: brow5,
    },
    {
      original: brow6,
    },
  ];

  const lips = [
    {
      original: lips1,
    },
    {
      original: lips2,
    },
    {
      original: lips3,
    },
    {
      original: lips4,
    },
    {
      original: lips5,
    },
    // {
    //   original: lips6,
    // },
  ];

  const cilia = [
    {
      original: cilia6,
    },
    // {
    //   original: cilia2,
    // },
    // {
    //   original: cilia3,
    // },
    // {
    //   original: cilia4,
    // },
    {
      original: cilia5,
    },
    // {
    //   original: cilia1,
    // },
    {
      original: cilia7,
    },
    {
      original: cilia8,
    },
    {
      original: cilia9,
    },
    {
      original: cilia10,
    },
    {
      original: cilia11,
    },
  ];

  return (
    <div className="content" id="ServicesContent">
      <div className="heading">
        <h2 className="text-ab-hh">Услуги</h2>
      </div>
      <div className="container-servise">
        <div className="item-servise">
          <div className="item-servise-content">
            <div className="item-servise__icon">
              <ImageGallery
                items={brow}
                showBullets={false}
                showPlayButton={false}
                showThumbnails={false}
                disableThumbnailScroll={true}
                showNav={true}
                showFullscreenButton={false}
              />
            </div>
            <div className="servise-content">
              <h4 className="heading-servise text-servise-h">Бровист</h4>
              <p className="text-ab-pp">
                В качестве мастера-бровиста я уделяю особое внимание
                формированию идеальных бровей, которые подчеркивают
                индивидуальность каждого клиента. Я работаю с различными
                техниками: от классического оформления до более современных
                методов, таких как микроблейдинг.
              </p>
              <p className="text-ab-pp">
                Моя цель — создать естественный и гармоничный вид, который будет
                радовать вас каждый день.
              </p>
            </div>
          </div>
          <div className="item-menu">
            <p className="text-ab-pp-item item">
              Оформление бровей (архитектура, коррекция, окрашивание)
            </p>
            <p className="text-ab-pp item time">30 мин</p>
            <p className="text-ab-pp item price">1 200 ₽</p>
          </div>
          <div className="item-menu">
            <p className="text-ab-pp-item item">
              Коррекция бровей (трейдинг, пинцет, воск)
            </p>
            <p className="text-ab-pp item time">15 мин</p>
            <p className="text-ab-pp item price">от 500 ₽</p>
          </div>
          <div className="item-menu">
            <p className="text-ab-pp-item item">Мужская коррекция бровей</p>
            <p className="text-ab-pp item time">15 мин</p>
            <p className="text-ab-pp item price">700 ₽</p>
          </div>
          <div className="item-menu">
            <p className="text-ab-pp-item item">
              Удаление нежелательных волос на лице (усы, подбородок, нос)
            </p>
            <p className="text-ab-pp item time">20 мин</p>
            <p className="text-ab-pp item price">от 500 ₽</p>
          </div>
          <div className="button-div">
            <a
              href="https://n1274061.yclients.com/company/687678/personal/select-services?o=m1980662"
              target="_blank"
              rel="noreferrer"
            >
              <button className="button-28">Записаться</button>
            </a>
          </div>
        </div>

        <div className="item-servise">
          <div className="item-servise-content">
            <div className="item-servise__icon">
              <ImageGallery
                items={cilia}
                showBullets={false}
                showPlayButton={false}
                showThumbnails={false}
                disableThumbnailScroll={true}
                showNav={true}
                showFullscreenButton={false}
              />
            </div>
            <div className="servise-content">
              <h4 className="heading-servise text-servise-h">Уход за ресницами</h4>
              <p className="text-ab-pp">
              Ламинирование ресниц — это еще одна моя страсть. С семилетним
              опытом я знаю, как сделать ваши ресницы более выразительными и
              ухоженными. Эта процедура не только придает объем и изгиб, но и
              укрепляет ресницы, делая их более здоровыми. Я всегда стараюсь
              учитывать пожелания клиентов и предлагаю индивидуальные решения,
              чтобы добиться наилучшего результата.
              </p>
            </div>
          </div>
          <div className="item-menu">
            <p className="text-ab-pp-item item">
            Окрашивание ресниц
            </p>
            <p className="text-ab-pp item time">15 мин</p>
            <p className="text-ab-pp item price">500 ₽</p>
          </div>
          <div className="item-menu">
            <p className="text-ab-pp-item item">
            Ламинирование ресниц (эффект 1,5-3 месяца)
            </p>
            <p className="text-ab-pp item time">1 ч</p>
            <p className="text-ab-pp item price">3 000 ₽</p>
          </div>
          <div className="item-menu">
            <p className="text-ab-pp-item item">Velvet ресниц (2-3 месяца)</p>
            <p className="text-ab-pp item time">1 ч</p>
            <p className="text-ab-pp item price">3 500 ₽</p>
          </div>
          <div className="button-div">
            <a
              href="https://n1274061.yclients.com/company/687678/personal/select-services?o=m1980662"
              target="_blank"
              rel="noreferrer"
            >
              <button className="button-28">Записаться</button>
            </a>
          </div>
        </div>

        <div className="item-servise">
          <div className="item-servise-content">
            <div className="item-servise__icon">
              <ImageGallery
                items={lips}
                showBullets={false}
                showPlayButton={false}
                showThumbnails={false}
                disableThumbnailScroll={true}
                showNav={true}
                showFullscreenButton={false}
              />
            </div>
            <div className="servise-content">
              <h4 className="heading-servise text-servise-h">Перманентный макияж</h4>
              <p className="text-ab-pp">
              В последние два года я активно занимаюсь перманентным макияжем.
              Эта процедура позволяет создать идеальный макияж на каждый день,
              который не требует дополнительных усилий. Я использую только
              качественные пигменты и современные техники, что обеспечивает
              долговечность и естественность результата.
              </p>
              <p className="text-ab-pp">
              Моя задача — подчеркнуть вашу красоту, сохраняя при этом
              естественность.
              </p>
            </div>
          </div>
          <div className="item-menu">
            <p className="text-ab-pp-item item">
            Брови в аппаратной технике "Пудра"
            </p>
            <p className="text-ab-pp item time">1 ч 30 мин</p>
            <p className="text-ab-pp item price">8 000 ₽</p>
          </div>
          <div className="item-menu">
            <p className="text-ab-pp-item item">
            Брови в мануальной технике "Волоски"
            </p>
            <p className="text-ab-pp item time">1 ч 30 мин</p>
            <p className="text-ab-pp item price">8 000 ₽</p>
          </div>
          <div className="item-menu">
            <p className="text-ab-pp-item item">Губы в бесконтурной "Акварельной" тахнике</p>
            <p className="text-ab-pp item time">1 ч 30 мин</p>
            <p className="text-ab-pp item price">8 000 ₽</p>
          </div>
          <div className="item-menu">
            <p className="text-ab-pp-item item">
            Веки в аппаратной технике "Межресничка"
            </p>
            <p className="text-ab-pp item time">1 ч</p>
            <p className="text-ab-pp item price">6 000 ₽</p>
          </div>
          <div className="item-menu">
            <p className="text-ab-pp-item item">
            Коррекция перманентного макияжа
            </p>
            <p className="text-ab-pp item time">1 ч 30 мин</p>
            <p className="text-ab-pp item price">5 000 ₽</p>
          </div>
          <div className="item-menu">
            <p className="text-ab-pp-item item">
            Удаление татуажа ремувером
            </p>
            <p className="text-ab-pp item time">1 ч</p>
            <p className="text-ab-pp item price">2 500 ₽ – 4 000 ₽</p>
          </div>
          <div className="button-div">
            <a
              href="https://n1274061.yclients.com/company/687678/personal/select-services?o=m1980662"
              target="_blank"
              rel="noreferrer"
            >
              <button className="button-28">Записаться</button>
            </a>
          </div>
        </div>
       
      </div>
    </div>
  );
}

export default ServicesContent;
